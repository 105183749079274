'use client'

import axios from 'axios'
import { memo, PropsWithChildren, useEffect, useMemo, useState } from 'react'

import useDrawerState from '@/hooks/context/useDrawerState'
import useGrantApplication from '@/hooks/useGrantApplication'

import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'

import { GrantApplicationContext } from './grant.context'

import { IQuestion } from '@/types/chatbot'
import { GrantApplicationMode } from '@/types/grants'

export const GrantApplicationProvider = memo(
  ({ children }: PropsWithChildren) => {
    const [questions, setQuestions] = useState<IQuestion[]>([])
    const [steps, setSteps] = useState<{ [key: string]: any }>({})
    const [currentStep, setCurrentStep] = useState<number>(-1)
    const [isMatcherOpen, setIsMatcherOpen] = useState(true)
    const [mode, setMode] = useState<GrantApplicationMode>()
    const { selectedConversation, mutateConversations } = useDrawerState()
    const { grantApplication } = useGrantApplication(selectedConversation)

    useEffect(() => {
      if (grantApplication) {
        setSteps(grantApplication.steps)
        setCurrentStep(grantApplication.currentStep)
        setMode(grantApplication.mode ?? GrantApplicationMode.RESEARCHING)
        setQuestions(grantApplication.questions)
      } else if (!selectedConversation) {
        setSteps({})
        setCurrentStep(-1)
        setMode(undefined)
        setQuestions([])
      }
    }, [grantApplication, selectedConversation])

    useEffect(() => {
      if (
        currentStep === 10 ||
        (mode === GrantApplicationMode.MATCHING && currentStep === 7) ||
        mode === GrantApplicationMode.RESEARCHING
      ) {
        setIsMatcherOpen(false)
      } else {
        setIsMatcherOpen(true)
      }
    }, [currentStep, mode])

    /* 
      Everytime the currentStep or steps data changes, store the grant application to the database
      Exception: when the currentStep is 0, because the conversation is not yet created.
      Therefore we call storeToDb() in the useEffect in the AgentResponseStep component
    */
    useEffect(() => {
      if (currentStep > 0 && !!steps) {
        storeToDb().then(() => mutateConversations())
      }
    }, [currentStep, steps])

    const storeToDb = async () => {
      const config = {
        method: 'post',
        withCredentials: true,
        ...configHeader,
        data: JSON.stringify({
          id: selectedConversation,
          data: JSON.stringify({
            currentStep,
            steps,
            mode,
            questions,
          }),
        }),
      }

      await axios(`${API_URL}/grant-thread`, config)
    }

    const contextValue = useMemo(() => {
      return {
        questions,
        steps,
        currentStep,
        mode,
        isMatcherOpen,
        storeToDb,
        setCurrentStep,
        setMode,
        setSteps,
        setQuestions,
        setIsMatcherOpen,
      }
    }, [
      questions,
      steps,
      currentStep,
      mode,
      isMatcherOpen,
      mutateConversations,
    ])

    return (
      <GrantApplicationContext.Provider value={contextValue}>
        {children}
      </GrantApplicationContext.Provider>
    )
  }
)
